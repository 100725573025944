body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Sarabun", sans-serif;
}

div,
td,
h1,
h2,
h3,
h4,
h5,
label,
h6 {
  font-family: "Sarabun", sans-serif !important;
  font-weight: 300;
}
th {
  font-family: "Sarabun", sans-serif !important;
  font-weight: 700 !important;
  text-align: center !important;
}
option,
select,
textarea,
input {
  font-family: "Sarabun", sans-serif !important;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
